import styled, { css } from "styled-components";

import {
  assets,
  colors,
  colorsRGB,
  fontSizes,
  PRIMARY_COLOR,
} from "./constants";
import {
  AlignItems,
  AlignSelf,
  FlexDirection,
  FontFam,
  JustifyContent,
  Overflow,
  PDisplayType,
  FlexWrap,
  Cursor,
  FontWeight,
  WhiteSpace,
  Color,
  Dimensions,
} from "./types";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";

export const Overlay = styled.div<{ zIndex?: number }>`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: ${(props) => props.zIndex ?? 3};
  left: 0;
  top: 0;
  background-color: ${colorsRGB.black(0.5)};
  overflow: hidden;
  transition: 0.5s;
`;

export const P = styled.p<{
  color?: Color;
  fontSize?: number;
  fontFamily?: FontFam;
  noMargin?: boolean;
  marginTop?: number;
  marginBottom?: number;
  width?: number;
  lineHeight?: number;
  underlined?: boolean;
  fontWeight?: FontWeight;
  opacity?: number;
  isMobileStyling?: boolean;
  display?: PDisplayType;
  whiteSpace?: WhiteSpace;
  cursor?: Cursor;
  padding?: number;
  textAlign?: string;
  letterSpacing?: string;
  margin?: string;
}>`
  text-decoration-line: ${(props) => (props.underlined ? "underline" : "none")};
  background-color: transparent;
  font-weight: ${(props) => props.fontWeight ?? "normal"};
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px;`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ noMargin }) => noMargin && `margin: 0px;`}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ padding }) => padding && `padding: ${padding}px;`}
  ${({ display }) => display && `display: ${display};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily};`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${(props) => props.fontSize ?? fontSizes.p.mobile}px;
    line-height: ${(props) => props.fontSize ?? fontSizes.p.mobile * 1.2}px;
  }

  ${({ isMobileStyling }) =>
    isMobileStyling &&
    `@media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
    text-align: center;
    margin: auto;
  }`}
`;

export const A = styled.a<{
  color?: Color;
  underlined?: boolean;
  bold?: boolean;
  hoverColor?: Color;
  opacity?: number;
  margin?: string;
}>`
  color: ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ underlined }) =>
    underlined ? "text-decoration: underline;" : "text-decoration: none;"}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ bold }) => bold && "font-weight: bold;"}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ hoverColor }) =>
    hoverColor &&
    `&:hover {
    color: ${colors[hoverColor]};
  }`}
`;

export const H1 = styled.h1<{
  fontFamily?: FontFam;
  noMargin?: boolean;
  fontWeight?: string;
  fontSize?: number;
  color?: Color;
}>`
  color: ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  font-size: ${(props) => props.fontSize ?? fontSizes.h1.default}px;
  ${({ noMargin }) => noMargin && `margin: 0px;`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h1.mobile}px;
    line-height: ${fontSizes.h1.mobile * 1.2}px;
  }
`;
export const H2 = styled.h2<{
  color?: Color;
  margin?: string;
  fontWeight?: FontWeight;
  fontFam?: FontFam;
}>`
  font-size: ${fontSizes.h2.default}px;
  white-space: initial;
  color: ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ fontFam }) => fontFam && `font-family: ${fontFam};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h2.mobile}px;
    line-height: ${fontSizes.h2.mobile * 1.2}px;
  }
`;

export const H3 = styled.h3<{
  color?: Color;
  margin?: string;
  fontWeight?: FontWeight;
  fontFam?: FontFam;
}>`
  font-size: ${fontSizes.h3.default}px;
  white-space: initial;
  color: ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ fontFam }) => fontFam && `font-family: ${fontFam};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h3.mobile}px;
    line-height: ${fontSizes.h3.mobile * 1.2}px;
  }
`;

export const VerticalSeparator = styled.div<{
  color?: Color;
  width?: string;
  height?: string;
  opacity?: number;
  shouldShowMobile?: boolean;
}>`
  border-left: 1.5px solid
    ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  height: ${(props) => (props.height ? props.height : `auto`)};
  width: ${(props) => (props.width ? props.width : `20px`)};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
`;

export const Separator = styled.div<{
  color?: Color;
  height?: string;
  opacity?: number;
  thickness?: number;
  shouldHideDesktop?: boolean;
  marginBottom?: number;
  width?: string;
}>`
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}px;` : `margin-bottom: 10px`};
  border-bottom: ${(props) => props.thickness ?? 2}px solid
    ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ width }) => width && `width: ${width ?? "100%"};`}
  height: ${(props) => props.height ?? `20px`};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}

  ${({ width }) => width && `width: ${width};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:block`}
  }
  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:none`}
  }
`;

export const Container = styled.div<{
  backgroundColor?: Color;
  backgroundRGBColor?: string;
  flexDirection?: FlexDirection;
  flex?: number | string;
  flexGrow?: number;
  flexWrap?: FlexWrap;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  alignSelf?: AlignSelf;
  mobileAlignSelf?: AlignSelf;
  borderRadius?: number;
  overflow?: Overflow;
  padding?: number;
  paddingVertical?: number;
  paddingHorizontal?: number;
  img?: string;
  isMobileColumn?: boolean;
  mobilePadding?: string;
  mobileJustifyContent?: JustifyContent;
  mobileDimensions?: Dimensions;
  tabletDimensions?: Dimensions;
  width?: string;
  height?: string;
  opacity?: number;
  margin?: string;
  cursor?: Cursor;
  reverseDirection?: boolean | null;
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  showOnTablet?: boolean;
  zIndex?: number;
}>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection ?? "initial"};
  justify-content: ${(props) => props.justifyContent ?? "initial"};
  align-items: ${(props) => props.alignItems ?? "initial"};
  align-self: ${(props) => props.alignSelf ?? "initial"};
  overflow: ${(props) => props.overflow ?? "initial"};
  border-radius: ${(props) => props.borderRadius ?? 0}px;
  background-size: cover;
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ padding }) => padding && `padding: ${padding}px;`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ paddingVertical }) =>
    paddingVertical &&
    `padding: 0px ${paddingVertical}px 0px ${paddingVertical}px;`}
    ${({ paddingHorizontal }) =>
    paddingHorizontal &&
    `padding: ${paddingHorizontal}px 0px ${paddingHorizontal}px 0px;`}
  ${({ img }) => img && `background-image: url(${img});`}
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${colors[backgroundColor]};`}
  ${({ backgroundRGBColor }) =>
    backgroundRGBColor && `background-color: ${backgroundRGBColor};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ reverseDirection }) =>
    reverseDirection && `flex-direction: row-reverse;`}
  ${({ showOnTablet }) => showOnTablet && `display: none;`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ isMobileColumn }) =>
    isMobileColumn &&
    `
      flex-direction: column;
      height: auto;
      align-items: center;
      width: 100%;
    `}

    ${({ mobilePadding }) => mobilePadding && `padding: ${mobilePadding}`}

    ${({ tabletDimensions }) =>
    tabletDimensions &&
    tabletDimensions.height &&
    `height: ${tabletDimensions.height};`}
  ${({ tabletDimensions }) =>
    tabletDimensions &&
    tabletDimensions.width &&
    `width: ${tabletDimensions.width};`}

      ${({ hideOnTablet }) => hideOnTablet && `display: none;`}
      ${({ showOnTablet }) => showOnTablet && `display: flex !important;`}
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileDimensions }) =>
    mobileDimensions &&
    mobileDimensions.height &&
    `height: ${mobileDimensions.height};`}
    ${({ mobileDimensions }) =>
    mobileDimensions &&
    mobileDimensions.width &&
    `width: ${mobileDimensions.width};`}

    ${({ hideOnMobile }) => hideOnMobile && `display: none;`}
    ${({ mobileAlignSelf }) =>
    mobileAlignSelf && `align-self: ${mobileAlignSelf}`}
  }
`;

export const GridContainer = styled.div<{
  gridTemplateColumns?: string;
  width?: string;
  height?: string;
  margin?: string;
  repeat?: number;
  mobileRepeat?: number;
  tabletRepeat?: number;
  columnGap?: number;
  rowGap?: number;
  rtl?: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.repeat ?? 4}, 1fr);
  ${({ columnGap }) => columnGap && `grid-column-gap: ${columnGap}px;`}
  ${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap}px;`}
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ rtl }) => rtl && `direction: rtl;`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(${(props) => props.mobileRepeat ?? 1}, 1fr);
  }
`;

export const TextButton = styled(P) <{
  color?: Color;
  hoverColor?: Color;
  underlined?: boolean;
  lineHeight?: number;
  margin?: string;
}>`
  line-height: ${(props) => props.lineHeight ?? 18}px;
  cursor: pointer;
  margin: 0;
  user-select: none;
  font-size: ${fontSizes.p}px;
  text-decoration-line: ${(props) => (props.underlined ? "underline" : "none")};
  color: ${(props) => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ margin }) => margin && `margin: ${margin};`}
  &:hover {
    color: ${(props) =>
    props.hoverColor ? colors[props.hoverColor] : PRIMARY_COLOR};
  }
`;

export const GiveMeSomeSpace = styled.div<{ space?: number }>`
  padding: ${(props) => props.space ?? 10}px;
`;

export const Select = styled.select`
  position: relative;
  padding: 10px;
  border: transparent;
  background-color: ${colors.grey};
  border-radius: 5px;
  justify-content: space-between;
  font-family: "Figtree";
  font-weight: 700;
  color: ${colors.white};
  font-size: ${fontSizes.p.default}px;
  background-image: url(${assets.downArrow});
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
`;


export const ScanGuideWrapper = styled(Container)`
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
`;

export const ScanGuide = styled.div`
  height: 70%;
  width: 70%;
  outline: rgba(0, 0, 0, .25) solid 50vmax;

  background: linear-gradient(to right, ${colors.orange} 3px, transparent 3px) 0
      0,
    linear-gradient(to right, ${colors.orange} 3px, transparent 3px) 0 100%,
    linear-gradient(to left, ${colors.orange} 3px, transparent 3px) 100% 0,
    linear-gradient(to left, ${colors.orange} 3px, transparent 3px) 100% 100%,
    linear-gradient(to bottom, ${colors.orange} 3px, transparent 3px) 0 0,
    linear-gradient(to bottom, ${colors.orange} 3px, transparent 3px) 100% 0,
    linear-gradient(to top, ${colors.orange} 3px, transparent 3px) 0 100%,
    linear-gradient(to top, ${colors.orange} 3px, transparent 3px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 15px 15px;
`;


export const VideoWrapper = styled.div<{ cameraAllowed: boolean; ready: boolean; }>`
  display: flex;
  overflow: hidden;
  align-items: stretch;
  flex: 0 0 auto;
  border-radius: 22px;
  position: relative;
  overflow: hidden;
  border: 2px solid ${(p) => p.ready ? colors.orange : colors.darkGrey};
  z-index: 1;
  margin-bottom: 12px;

  background: black url(${(p) => p.ready ? "" : assets.qrIcon3}) no-repeat center center;
  // background: ${(p) => p.cameraAllowed ? css`black` : css`black url(${assets.qrIcon3}) no-repeat center center`};
  background-size: 20%;

  @keyframes pulse {
    0% {  opacity: 0.3;  }
    50% { opacity: 0.6;  }
    100% {  opacity: 0.3;  }
  }
  
  animation: ${(p) => p.ready ? "none" : "pulse 1.5s infinite ease-in-out"};

  .scan-region-highlight {
    // border-radius: 16px;
    outline: rgba(0, 0, 0, .25) solid 50vmax;
    
    background: linear-gradient(to right, ${colors.orange} 3px, transparent 3px) 0 0,
    linear-gradient(to right, ${colors.orange} 3px, transparent 3px) 0 100%,
    linear-gradient(to left, ${colors.orange} 3px, transparent 3px) 100% 0,
    linear-gradient(to left, ${colors.orange} 3px, transparent 3px) 100% 100%,
    linear-gradient(to bottom, ${colors.orange} 3px, transparent 3px) 0 0,
    linear-gradient(to bottom, ${colors.orange} 3px, transparent 3px) 100% 0,
    linear-gradient(to top, ${colors.orange} 3px, transparent 3px) 0 100%,
    linear-gradient(to top, ${colors.orange} 3px, transparent 3px) 100% 100%;   
    background-repeat: no-repeat;
    background-size: 15px 15px;
  }

  .scan-region-highlight-svg {
    display: none;
  }
`;
