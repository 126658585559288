import React from "react";
import styled from "styled-components";

import { Color, Dimensions } from "@util/types";
import {
  colorsRGB,
  BUTTON_HEIGHT,
  MOBILE_BREAKPOINT,
  PRIMARY_COLOR,
  colors,
  fontSizes,
} from "@util/constants";

const StyledInput = styled.input<{
  dimensions?: Dimensions;
  margin?: string;
  fontSize?: number;
  mobileMargin?: string;
  hidden?: boolean;
  color?: Color;
  isTransparent?: boolean;
}>`
  display: flex;
  flex: 1;
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ mobileMargin }) =>
    mobileMargin &&
    ` @media only screen and (max-width: ${MOBILE_BREAKPOINT}px){
    margin: ${mobileMargin};
  }`}
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : fontSizes.p.default}px;
  ${({ dimensions }) => (dimensions ? `width: ${dimensions.width};` : "100%;")}
  ${({ hidden }) => hidden && `display: none;`}
  color: ${colors.white};
  background-color: ${(props) =>
    props.isTransparent ? colorsRGB.white() : colors.darkGrey};
  border-radius: 5px;
  border: 2px solid 
    ${(props) => (props.color ? colors[props.color] : colors.transparent)};
  font-family: "Figtree";
  font-weight: 400;

  padding: 15px;
  ::placeholder {
    color: ${(props) =>
    props.isTransparent ? colors.white : colors.lightGrey};
    opacity: 1;
  }
  &:focus {
    outline: 0;
  }
  &::-webkit-input-placeholder {
        font-weight: 400;
  }
`;

const StyledTextArea = styled.textarea<{
  dimensions?: Dimensions;
  hidden?: boolean;
}>`
  display: flex;
  flex: 1;
  padding: 10px;
  ${({ dimensions }) => (dimensions ? `width: ${dimensions.width};` : "100%;")}
  ${({ hidden }) => hidden && `display: none;`}
  height: ${(props) => props.dimensions?.height ?? `${BUTTON_HEIGHT}px`};
  border-radius: 10px;
  border: 1px solid ${colorsRGB.flightBlue()};
  font-family: "roboto";
  padding-left: 20px;
  padding-right: 20px;
  color: ${PRIMARY_COLOR};
  &:focus {
    outline: 0;
  }
  &::-webkit-input-placeholder {
    font-family: "roboto";
  }
`;

interface Props {
  dimensions?: Dimensions;
  mobileMargin?: string;
  margin?: string;
  fontSize?: number;
  placeholderText?: string;
  name?: string;
  onChange?: (arg?: any) => void;
  value?: string;
  defaultValue?: string;
  isTextArea?: boolean;
  type?: string;
  required?: boolean;
  hidden?: boolean;
  color?: Color;
  onKeyDown?: (arg: any) => void;
  isTransparent?: boolean;
  autoFocus?: boolean;
}

const TextInput = (props: Props) => {
  if (props.isTextArea) {
    return (
      <StyledTextArea
        name={props.name}
        placeholder={props.placeholderText}
        onChange={props.onChange}
        value={props.value}
        dimensions={props.dimensions}
        hidden={props.hidden}
      />
    );
  }

  return (
    <StyledInput
      required={props.required}
      name={props.name}
      fontSize={props.fontSize}
      margin={props.margin}
      mobileMargin={props.mobileMargin}
      placeholder={props.placeholderText}
      onChange={props.onChange}
      value={props.value}
      defaultValue={props.defaultValue}
      dimensions={props.dimensions}
      type={props.type}
      hidden={props.hidden}
      color={props.color}
      onKeyDown={props.onKeyDown}
      isTransparent={props.isTransparent}
      autoFocus={props.autoFocus}
    />
  );
};

export default TextInput;
