import React from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";

import { ButtonTheme, Color, Dimensions, FontFam } from "@util/types";
import {
  buttonStyle,
  colors,
  fontSizes,
  MOBILE_BREAKPOINT,
} from "@util/constants";
import Loading from "./loading";
import { Container, A } from "@util/standard";

const StyledButton = styled.button<{
  theme: ButtonTheme;
  dimensions?: Dimensions;
  mobileDimensions?: Dimensions;
  disableHover?: boolean;
  mobileMargin?: string;
  margin?: string;
  minHeight?: number;
  width?: string;
  padding?: string;
  fontSize?: number;
  color?: Color;
  borderColor?: Color;
  fontFam?: FontFam;
}>`
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : fontSizes.p.default}px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  ${({ margin }) => margin && `margin: ${margin};`}
  padding: ${(props) => (props.padding ? props.padding : `15px 35px`)};
  ${({ width }) => width && `width: ${width};`}
  height: ${(props) => props.dimensions?.height ?? `auto`};
  color: ${(props) => props.theme.text};
  border: 2px solid
    ${(props) =>
    props.borderColor ? colors[props.borderColor] : props.theme.border};
  background-color: ${(props) =>
    props.color ? colors[props.color] : props.theme.bg};
  transition: background-color 0.4s linear;
  font-weight: 700;
  font-family: ${(props) => props.fontFam ?? "Figtree"};

  &:focus {
    outline: 0;
  }
  ${({ disableHover, theme }) =>
    !disableHover &&
    `&:hover {
      outline: 0;
      border-color: ${theme.hoverBorder};
      color: ${theme.hoverText};
      background-color: ${theme.hoverBg};
  }`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileDimensions }) => mobileDimensions && `width:${mobileDimensions}`}
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
    line-height: ${fontSizes.h3.mobile * 1.2}px;
    padding: ${(props) => (props.padding ? props.padding : `10px 30px`)};
  }

  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
`;

const LoadingContainer = styled(Container)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

interface Props {
  theme?: ButtonTheme;
  disabled?: boolean;
  className?: string;
  dimensions?: Dimensions;
  mobileDimensions?: Dimensions;
  minHeight?: number;
  text: string;
  margin?: string;
  padding?: string;
  disableHoverEffect?: boolean;
  mobileMargin?: string;
  onClick?: (args?: any) => void;
  onMouseDown?: (args?: any) => void;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  linkTo?: string;
  linkToNewWindow?: string;
  width?: string;
  fontSize?: number;
  color?: Color;
  borderColor?: Color;
  fontFam?: FontFam;
}

const Button = ({
  theme = "base",
  disabled,
  dimensions,
  width,
  mobileDimensions,
  text,
  margin,
  disableHoverEffect,
  onClick,
  mobileMargin,
  loading,
  minHeight,
  padding,
  type,
  linkTo,
  linkToNewWindow,
  fontSize,
  color,
  borderColor,
  fontFam,
  onMouseDown
}: Props) => {
  const handleOnClick = () => {
    if (loading || onMouseDown) {
      return;
    }
    if (onClick) {
      onClick();
    }
  };

  const RenderedButton = ({
    externalLink,
  }: {
    externalLink?: string | undefined;
  }) => (
    <StyledButton
      theme={buttonStyle[theme]}
      dimensions={dimensions}
      width={width}
      disabled={disabled}
      onClick={handleOnClick}
      margin={margin}
      padding={padding}
      mobileDimensions={mobileDimensions}
      disableHover={loading || disableHoverEffect}
      mobileMargin={mobileMargin}
      minHeight={minHeight}
      type={type}
      fontSize={fontSize}
      color={color}
      borderColor={borderColor}
      fontFam={fontFam}
      onMouseDown={onMouseDown}
    >
      {loading ? (
        <LoadingContainer>
          <Loading dimensions={{ height: 25, width: 25 }} />
        </LoadingContainer>
      ) : externalLink ? (
        <A
          href={externalLink}
          hoverColor="white"
          target="_blank"
          rel="noreferrer"
        >
          {text}
        </A>
      ) : (
        text
      )}
    </StyledButton>
  );

  if (linkTo) {
    return (
      <GatsbyLink to={linkTo}>
        <RenderedButton />
      </GatsbyLink>
    );
  }

  return <RenderedButton externalLink={linkToNewWindow} />;
};

export default Button;
